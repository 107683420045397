var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('page-banner',{attrs:{"links":"Mes formations"}}),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.dialogNew),callback:function ($$v) {_vm.dialogNew=$$v},expression:"dialogNew"}},[_c('v-card',[_c('v-card-title',[_vm._v("Nouvelle formation ")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Nom de la formation","autofocus":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.dialogNew = false;
                        _vm.newFormation(true);}},model:{value:(_vm.newName),callback:function ($$v) {_vm.newName=$$v},expression:"newName"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error darken-1","text":""},on:{"click":function($event){_vm.dialogNew = false;
                        _vm.newName = '';}}},[_vm._v(" Annuler ")]),_c('v-btn',{attrs:{"color":"success darken-2","text":""},on:{"click":function($event){_vm.dialogNew = false;
                        _vm.newFormation(true);}}},[_vm._v(" Créer la formation ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',[_vm._v("Suppression de \""+_vm._s(_vm.formationToDeleteTitle)+"\"")]),_c('v-card-text',[_vm._v("Êtes vous sûr de vouloir supprimer cette formation ? Si oui veuillez écire \"Supprimer\" dans le champs ci-dessous")]),_c('v-card-text',[_c('v-text-field',{attrs:{"autofocus":""},model:{value:(_vm.confirmDelete),callback:function ($$v) {_vm.confirmDelete=$$v},expression:"confirmDelete"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialogDelete = false;
                        _vm.askForDelete = null;
                        _vm.confirmDelete = '';}}},[_vm._v(" Annuler ")]),_c('v-btn',{attrs:{"color":"error darken-2","text":"","disabled":_vm.confirmDelete !== 'Supprimer'},on:{"click":function($event){_vm.dialogDelete = false;
                        _vm.confirmDelete = '';
                        _vm.deleteFormation();}}},[_vm._v(" Supprimer ")])],1)],1)],1),_c('v-row',[_c('v-card',{staticClass:"rounded-lg elevation-1 py-4 px-6",attrs:{"width":"100%"}},[_vm._l((_vm.formationStateList),function(state,identifier){return [_c('v-chip',{key:state.name,attrs:{"outlined":identifier !== _vm.currentFilter,"color":state.color},on:{"click":function($event){return _vm.changeFilter(identifier)}}},[_vm._v(_vm._s(state.name))])]})],2)],1),_c('v-row',{staticClass:"mt-10 mb-7"},[_c('span',{staticClass:"text-h5"},[_vm._v("Ma bibliothèque de formations")])]),_c('v-row',[_c('v-col',{staticClass:"formation_list pa-0"},_vm._l((_vm.filteredFormations),function(formation){return _c('v-row',{key:formation.id},[_c('v-card',{staticClass:"py-6 px-5 rounded-0",attrs:{"width":"100%"},on:{"click":function($event){return _vm.goTo(formation.id)}}},[_c('v-row',[_c('v-col',{staticClass:"align-self-center",attrs:{"md":"5"}},[_vm._v(" "+_vm._s(formation.title)+" ")]),_c('v-col',{staticClass:"align-self-center",attrs:{"md":"2"}},[_vm._v(" "+_vm._s(formation.User.firstName)+" "+_vm._s(formation.User.lastName)+" "),(formation.User.id !== _vm.user.id && _vm.user.companyName)?[_vm._v("("+_vm._s(_vm.user.companyName)+")")]:_vm._e()],2),_c('v-col',{staticClass:"align-self-center",attrs:{"md":"2","lg":"1","offset-md":"1","offset-lg":"2"}},[_c('v-chip',{attrs:{"outlined":"","color":_vm.formationStateList[formation.status].color}},[_vm._v(_vm._s(_vm.formationStateList[formation.status].name))])],1),_c('v-col',{staticClass:"text-end",attrs:{"md":"1"}},[_c('download-button',{attrs:{"download-type":"program","object-id":formation.id}})],1),_c('v-col',{attrs:{"md":"1"}},[(formation.User.id === _vm.user.id)?_c('v-menu',{attrs:{"offset-y":"","max-height":"500px","close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-ellipsis-v")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.duplicateFormation(formation.id)}}},[_c('v-list-item-title',[_vm._v("Dupliquer")]),_c('v-list-item-icon',[_c('v-icon',[_vm._v("far fa-copy")])],1)],1),_c('v-list-item',{on:{"click":function($event){_vm.dialogDelete = true;
                                            _vm.askForDelete = formation.id;}}},[_c('v-list-item-title',[_vm._v("Supprimer")]),_c('v-list-item-icon',[_c('v-icon',[_vm._v("far fa-trash-alt")])],1)],1)],1)],1):_c('v-icon',{staticClass:"ml-1 mt-2",attrs:{"title":"Ce document est partagé par votre organisation."}},[_vm._v("fas fa-user-friends")])],1)],1)],1)],1)}),1)],1),_c('v-row',{staticClass:"mt-5"},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.newFormation}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("fas fa-plus")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }