<template>
    <v-container fluid>
        <page-banner links="Mes formations"></page-banner>

        <v-dialog v-model="dialogNew" max-width="400">
            <v-card>
                <v-card-title>Nouvelle formation </v-card-title>
                <v-card-text>
                    <v-text-field
                        label="Nom de la formation"
                        v-model="newName"
                        autofocus
                        @keydown.enter="
                            dialogNew = false;
                            newFormation(true);
                        "
                    ></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error darken-1"
                        text
                        @click="
                            dialogNew = false;
                            newName = '';
                        "
                    >
                        Annuler
                    </v-btn>

                    <v-btn
                        color="success darken-2"
                        text
                        @click="
                            dialogNew = false;
                            newFormation(true);
                        "
                    >
                        Créer la formation
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="400">
            <v-card>
                <v-card-title>Suppression de "{{ formationToDeleteTitle }}"</v-card-title>
                <v-card-text
                    >Êtes vous sûr de vouloir supprimer cette formation ? Si oui veuillez écire "Supprimer" dans le champs
                    ci-dessous</v-card-text
                >
                <v-card-text><v-text-field v-model="confirmDelete" autofocus></v-text-field></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="
                            dialogDelete = false;
                            askForDelete = null;
                            confirmDelete = '';
                        "
                    >
                        Annuler
                    </v-btn>

                    <v-btn
                        color="error darken-2"
                        text
                        :disabled="confirmDelete !== 'Supprimer'"
                        @click="
                            dialogDelete = false;
                            confirmDelete = '';
                            deleteFormation();
                        "
                    >
                        Supprimer
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-row>
            <v-card width="100%" class="rounded-lg elevation-1 py-4 px-6">
                <template v-for="(state, identifier) in formationStateList" >
                    <v-chip  :outlined="identifier !== currentFilter" :color="state.color" :key="state.name" @click="changeFilter(identifier)">{{ state.name }}</v-chip>
                </template>
            </v-card>
        </v-row>

        <v-row class="mt-10 mb-7">
            <span class="text-h5">Ma bibliothèque de formations</span>
        </v-row>

        <v-row>
            <v-col class="formation_list pa-0">
                <v-row v-for="formation in filteredFormations" :key="formation.id">
                    <v-card width="100%" class="py-6 px-5 rounded-0" @click="goTo(formation.id)">
                        <v-row>
                            <v-col md="5" class="align-self-center">
                                {{ formation.title }}
                            </v-col>
                            <v-col md="2" class="align-self-center">
                                {{ formation.User.firstName }} {{ formation.User.lastName }} <template v-if="formation.User.id !== user.id && user.companyName">({{ user.companyName }})</template>
                            </v-col>
                            <v-col md="2" lg="1" offset-md="1" offset-lg="2" class="align-self-center">
                                <v-chip outlined :color="formationStateList[formation.status].color">{{ formationStateList[formation.status].name }}</v-chip>
                            </v-col>
                            <v-col md="1" class="text-end">
                                <download-button download-type="program" :object-id="formation.id"></download-button>
                            </v-col>
                            <v-col md="1">
                                <v-menu offset-y max-height="500px" :close-on-content-click="true" v-if="formation.User.id === user.id">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on">
                                            <v-icon>fas fa-ellipsis-v</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            @click="duplicateFormation(formation.id)"
                                        >
                                            <v-list-item-title>Dupliquer</v-list-item-title>
                                            <v-list-item-icon><v-icon>far fa-copy</v-icon></v-list-item-icon>
                                        </v-list-item>
                                        <v-list-item
                                            @click="
                                                dialogDelete = true;
                                                askForDelete = formation.id;
                                            "
                                        >
                                            <v-list-item-title>Supprimer</v-list-item-title>
                                            <v-list-item-icon><v-icon>far fa-trash-alt</v-icon></v-list-item-icon>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                <v-icon v-else title="Ce document est partagé par votre organisation." class="ml-1 mt-2">fas fa-user-friends</v-icon>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-row>
            </v-col>
        </v-row>

        <v-row class="mt-5">
            <v-btn icon @click="newFormation"><v-icon color="black">fas fa-plus</v-icon></v-btn>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PageBanner from "@/components/PageBanner";
import DownloadButton from "@/components/designer/DownloadButton";

export default {
    name: "FormationsList",
    components: {
        DownloadButton,
        PageBanner,
    },
    data: () => ({
        dialogNew: false,

        dialogDelete: false,
        confirmDelete: "",
        askForDelete: "",

        newName: "",

        searchParam: "",
        currentFilter: null,
    }),

    created() {
        this.getFormations();
    },

    computed: {
        ...mapState("settings", ["user"]),

        ...mapState("formations", ["formations"]),

        ...mapState("global", ["formationStateList"]),


        filteredFormations() {
            return this.currentFilter ? this.formations.filter((obj) => obj.status === this.currentFilter) : this.formations;
        },

        formationToDeleteTitle() {
            try {
                return this.formations.find((obj) => obj.id === this.askForDelete).title;
            } catch (e) {
                return "";
            }
        },
    },

    methods: {
        ...mapActions("formations", ["getFormations", "setCurrentFormation", "addFormation", "removeFormation", "duplicateFormation"]),

        ...mapActions("global", ["addAlert"]),

        goTo(e) {
            this.setCurrentFormation(e);
            this.$router.push({ name: "FormationScoping", params: { id: e } });
        },

        getColorChip(status) {
            if (status === "complete") {
                return "$finished";
            } else if (status === "ongoing") {
                return "$started";
            } else {
                return "$todo";
            }
        },

        deleteFormation() {
            this.removeFormation(this.askForDelete);
        },

        async newFormation(toCreate) {
            if (toCreate === true) {
                const formationId = await this.addFormation(this.newName);

                this.goTo(formationId);
            } else {
                this.dialogNew = true;
            }
        },

        isAbleToCreateFormation() {
            if(this.user.hasValidSubscription) {
                return true;
            }

            if(this.formations.length < process.env.VUE_APP_FREE_MAX_FORMATIONS) {
                return true;
            }

            return false;
        },

        changeFilter(identifier) {
            this.currentFilter = this.currentFilter === identifier ? null : identifier;
        },
    },
};
</script>

<style scoped>
.hoverBtn {
    transition: opacity 0.2s ease-in-out;
}

.hoverBtn:not(.onHover) {
    opacity: 0.5;
}

.v-chip {
    margin: 0 5px;
}

.formation_list > .row{
    margin: 1px 0;
}

.v-dialog__content .v-card__title {
    word-break: break-word;
}
</style>
